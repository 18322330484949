import { Component, OnInit } from '@angular/core';
import { DcbaService } from '../services/dcba/dcba.service';
import { LocalStorageService } from '../services/localStorage/local-storage.service';
import { apiParams } from '../utils/dcba.contstants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  public successResponseData: any;

  constructor(
    private dcbaSvc: DcbaService,
    private localSvc: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.successResponseData = this.dcbaSvc.getSuccessData();
    // console.log('successResponseData', this.successResponseData);
  }

  // async unsubscribe() {
  //   const RefID =  await this.localSvc.getData(apiParams.refId);
  //   const Msisdn =  await this.localSvc.getData(apiParams.msisdn);
  //   const TelcoId = await this.localSvc.getData(apiParams.telcoId);
  //     // const RefID = this.successResponseData.data.RefID;
  //     // const Msisdn = this.successResponseData.data.Msisdn;
  //     // const TelcoId = this.successResponseData.data.TelcoId;
  //   this.dcbaSvc.unsubscribeService(RefID, Msisdn, TelcoId ).subscribe(
  //     (res) => {
  //       let data = confirm(res.body.msg);
  //       if (data) {
  //         this.router.navigate(['/']);
  //       }
  //       this.unsubscribeInDb();
  //     },
  //     (err) => {
  //       alert(err);
  //     }
  //   );
  // }

  // async unsubscribeInDb() {
  //   const Msisdn =  await this.localSvc.getData(apiParams.msisdn);
  //   this.dcbaSvc.UnsubscribeDataInDb(Msisdn).subscribe(
  //     (res) => {
  //       console.log('res', res.body);
  //     },
  //     (error) => {
  //       alert(error);
  //     }
  //   );
  // }

  goToHome() {
    this.router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

}
