import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'mojo-games';
  languageStatus: string;
  defaultLang: string;
  // showItems: boolean = true;
  constructor(private router: Router) { }

  ngOnInit() {
    document.cookie = "name=" + '“/en/th”';
    this.defaultLang = localStorage.getItem('language');
    if (this.defaultLang === null || this.defaultLang === undefined) {
      this.languageStatus = '2';
      localStorage.setItem('language', this.languageStatus);
    } else {
      localStorage.setItem('language', this.defaultLang);
    }
  }

  changeLanguage(e) {
    console.log('e.target.value', e.target.value);
    if (e.target.value === 'en') {
      if (this.defaultLang !== '1') {
        localStorage.setItem('language', '1');
        window.location.reload();
      }
    } else if (e.target.value === 'th') {
      if (this.defaultLang !== '2') {
        localStorage.setItem('language', '2');
        window.location.reload();
      }
    }
  }

}
